import React from 'react'
import css from "./Footer.module.css"
import Logo from "../../assets/logo.png"
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
} from "@heroicons/react/outline"
function Footer() {
  return (
    <div className={css.cFooterWrapper}>
        <hr />
        <div className={css.cFooter}>

            <div className={css.clogo}>
                <img src ={Logo} alt ="" />
                <span>Amazon</span>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact US</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon} />
                        <span>11 Rue Mansour Dahbi , Oujda 60000</span>
                    </span>

                    <span className={css.pngLine}>
                        <PhoneIcon className={css.icon} />
                        <a href='tel:06-25-64-51-49'>0625645149</a>
                    </span>
                    <span className={css.pngLine}>
                        <InboxIcon className={css.icon} />
                        <a href='mailto:daizesamia@gmail.com'>daizesamia@gmail.com</a>
                    </span>
                </div>

            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account </span>
                    <span className={css.pngLine}>
                        <LoginIcon className={css.icon} />
                        <span>Sign in</span>
                    </span>

                </div>

            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company </span>
                    <span className={css.pngLine}>
                        <UsersIcon className={css.icon} />
                        <a href='/about'>
                            <p>About us</p>
                        </a>
                    </span>

                </div>

            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Ressources </span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon} />
                        <p>Safety Privacy & Terms</p>
                    </span>

                </div>

            </div>

        </div>
        <div className={css.copyRight}>
            <span>Copyright 2023 by Amazon , Inc.</span>
            <span>All right reserved .</span>

        </div>

    </div>
  )
}

export default Footer