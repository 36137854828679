import React from 'react'
import css from './Hero.module.css'
import HeroImg from "../../assets/hero.png"
import {RiShoppingBagFill} from "react-icons/ri"
import {BiRightArrowAlt} from "react-icons/bi"
import {motion} from "framer-motion"

function Hero() {
  //specify the kind of animation
  const transition = {duration:3, type:"spring"}
  return (
    <div className={css.container}>
        {/* left side */}
        <div className={css.h_sides}>
            <span className={css.text1}>Skin protection cream</span>
            
            <div className={css.text2}>
                <span>Trendy Collection</span>
                <span>Seedily say has suitable disposal ans boy . 
                    Exercise joy man children 
                    rejoiced.</span>
            </div>
        </div>

        {/* middle side */}
        <div className={css.wrapper}>
          {/*blue circle */}
            <motion.div 
            initial = {{bottom : "-2rem"}}
            whileInView = {{bottom : "0rem"}}
            className={css.blueCircle}
            transition = {transition}></motion.div>
            {/*hero img */}
            <motion.img 
            transition={transition}
            initial= {{bottom:"2rem"}}
            whileInView = {{bottom:"0rem"}}
            src={HeroImg} alt ="" width={600}/>

            {/* cart div animating */}
            <motion.div 
            transition={transition}
            initial = {{right:"4%"}}
            whileInView = {{right:"1%"}}
            className={css.cart2}>
              <RiShoppingBagFill />
              <div className={css.signup}>
                <span>Best signup Offers</span>
                <div>
                  <BiRightArrowAlt />
                </div>
                </div>    
            </motion.div>        
        </div>

        {/* Right side */}
        <div className={css.h_sides}>
          <div className={css.traffic}>
            <span>1.5m</span>
            <span>Monthly Traffic</span>
          </div>
          <div className={css.customers}>
            <span>100k</span>
            <span>Happy Customers</span>
          </div>
        </div>

    </div>
  )
}

export default Hero